<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <!-- 第二行 -->
        <Menu name="video-video"
              @change="onProInfoChange($event)" />
        <a-spin :spinning="loading">
          <dv-loading v-if="loading">Loading...</dv-loading>
          <div v-else
               id="content">
            <div class="tool-pln">
              <div class="btn-pln">
                <div v-for="n in screenNumberList"
                     :key="n.number"
                     :class="{
                    active: ScreenNumber == n.number,
                    'btn-item': ScreenNumber != n.number
                  }"
                     @click="InitScreen(n.number)">
                  <svg class="icon"
                       aria-hidden="true">
                    <use :xlink:href="n.icon"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div id="center"
                 ref="center">
              <div v-for="i in VueAliplayer"
                   :key="i.index"
                   :width="VideoWidth"
                   :height="VideoHeight"
                   :class="{
                  'active-item-box': SelectVideoIndex == i.index,
                  'item-box': SelectVideoIndex != i.index
                }"
                   @click="ScreenClick(i.index)"
                   style="position: relative">
                <div style="width: 100%; height: 100%; z-index: 100">
                  <!-- <vue-aliplayer-v2 class="multiple-player"
                                    :ref="i.tag"
                                    :options="options"
                                    :source="convertAddress(i.Data.LiveAddress)" /> -->

                  <video :id="`videoPlayer-`+i.index"
                         class="video-js vjs-default-skin"
                         style="width: 100%; height: 100%; object-fit: fill"></video>

                </div>
                <div style="z-index: 1000; height: 160px"
                     v-if="i.Data && i.Data.LiveAddress && i.Data.ControlAble">
                  <div v-if="!i.visible"
                       class="ptz-block-center">
                    <div title="云台控制"
                         class="ptz-center">
                      <a-icon type="drag"
                              @click="openPTZ(i)" />
                    </div>
                  </div>
                  <div v-else
                       v-drag
                       class="ptz-block">
                    <div style="
                        padding: 5px;
                        color: #fff;
                        text-align: center;
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      ">
                      {{ i.Data.title }}
                      <a-icon type="close-circle"
                              @click="closePTZ(i)"
                              style="position: absolute; right: -7px; top: -7px" />
                    </div>
                    <div style="height: 140px; width: 100px; position: absolute">
                      <div title="上"
                           class="ptz-cell ptz-up up ptz">
                        <a-icon v-if="!i.Loading.up"
                                @mousedown="ptzcontrol(i.Id, 'up')"
                                type="up-circle" />
                        <a-icon v-if="i.Loading.up"
                                @mouseup="stopcontrol(i.Id, 'up')"
                                type="loading" />
                      </div>
                      <div title="左"
                           class="ptz-cell ptz-left left ptz">
                        <a-icon v-if="!i.Loading.left"
                                @mousedown="ptzcontrol(i.Id, 'left')"
                                type="left-circle" />
                        <a-icon v-if="i.Loading.left"
                                @mouseup="stopcontrol(i.Id, 'left')"
                                type="loading" />
                      </div>
                      <div title="云台控制"
                           class="ptz-center">
                        <a-icon type="drag"
                                :class="{
                            'active-ptz': i.Loading.loading
                          }" />
                      </div>
                      <div title="右"
                           class="ptz-cell ptz-right right ptz">
                        <a-icon v-if="!i.Loading.right"
                                @mousedown="ptzcontrol(i.Id, 'right')"
                                type="right-circle" />
                        <a-icon v-if="i.Loading.right"
                                @mouseup="stopcontrol(i.Id, 'right')"
                                type="loading" />
                      </div>
                      <div title="下"
                           class="ptz-cell ptz-down down ptz">
                        <a-icon @mousedown="ptzcontrol(i.Id, 'down')"
                                v-if="!i.Loading.down"
                                type="down-circle" />
                        <a-icon v-if="i.Loading.down"
                                @mouseup="stopcontrol(i.Id, 'down')"
                                type="loading" />
                      </div>
                      <div title="缩小"
                           class="ptz-cell ptz-plus zoomin focusing">
                        <a-icon @mousedown="ptzcontrol(i.Id, 'IRIS_REDUCE')"
                                v-if="!i.Loading.zoomin"
                                type="minus-circle" />
                        <a-icon v-if="i.Loading.zoomin"
                                @mouseup="stopcontrol(i.Id, 'IRIS_REDUCE')"
                                type="loading" />
                      </div>
                      <div title="放大"
                           class="ptz-cell ptz-minus zoomout focusing">
                        <a-icon @mousedown="ptzcontrol(i.Id, 'IRIS_ENLARGE')"
                                v-if="!i.Loading.zoomout"
                                type="plus-circle" />
                        <a-icon v-if="i.Loading.zoomout"
                                @mouseup="stopcontrol(i.Id, 'IRIS_ENLARGE')"
                                type="loading" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="right">
              <a-tree v-if="videoList && videoList.length"
                      showIcon
                      default-expand-all
                      :treeData="videoList"
                      style="max-height: 10.5rem;  overflow: auto;"
                      @select="SelectVideoAddress">
                <template slot="title"
                          slot-scope="item">
                  <a-badge v-if="
                      VueAliplayer.filter(item => item.Id === item.Id).length >
                        0
                    "
                           :count="
                      VueAliplayer.filter(item => item.Id === item.Id)[0].index
                    " />
                  <a-icon v-if="item.Added"
                          slot="video-camera"
                          type="camera" />
                  <a-icon v-else
                          slot="nvr"
                          type="video-camera" />
                  <span>{{ item.title }}</span>
                </template>
              </a-tree>
            </div>
          </div>
        </a-spin>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache";
//import VueAliplayerV2 from "@/components/AliplayerV2";

import 'videojs-contrib-hls'
import "@/assets/font/iconfont";
import { message } from "ant-design-vue";
const screenNumberList = [
  { icon: "#icon-shipinfenping1s", number: 1 },
  { icon: "#icon-shipinfenping4s", number: 4 },
  { icon: "#icon-shipinfenping6s", number: 6 },
  { icon: "#icon-shipinfenping8s", number: 8 },
  { icon: "#icon-shipinfenping9s", number: 9 },
  { icon: "#icon-shipinfenping16s", number: 16 },
  { icon: "#icon-shipinfenping25s", number: 25 }
];

export default {
  data () {
    return {
      loading: true,
      pros: {},
      pro: {},
      screenNumberList,
      ScreenNumber: 1,
      VideoWidth: 352,
      VideoHeight: 288,
      SelectVideoIndex: 0,
      Container: {},
      VueAliplayer: [],
      videoList: [],
      CameraCount: 0,
      options: {
        autoplay: true,
        controls: true,
        muted: false,
        bigPlayButton: true,
        textTrackDisplay: false,
        posterImage: false,
        errorDisplay: false,
        hls: {
          withCredentials: true
        }
      },
    };
  },
  components: {
    Menu,
    //VueAliplayerV2
  },
  created: function () {
    this.pro = ProInfoCache.getProInfo();
    for (let index = 0; index < 25; index++) {
      let flag1 = this.VueAliplayer.findIndex(item => item.index === index);
      if (flag1 === -1)
        this.VueAliplayer.push({
          index: index,
          tag: "VueAliplayer" + index
        });
    }
  },
  beforeDestroy () {
    for (let index = 0; index < this.VueAliplayer.length; index++) {
      const element = this.VueAliplayer[index];
      if (element && element.player)
        element.dispose()
    }
  },
  mounted () {
    this.initVideoForm();
  },
  directives: {
    drag (el) {
      el.onmousedown = function (e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + "px";
          el.style.top = e.pageY - disy + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    }
  },
  watch: {},
  methods: {
    onProInfoChange (e) {
      this.pro = e;
      this.initVideoForm();
    },
    initVideoForm () {
      this.loadViedo();
      this.cancelLoading();
    },
    cancelLoading () {
      this.loading = true;
      setTimeout(() => {
        this.InitScreen(1);
      }, 1000);
      this.loading = false;
    },
    loadViedo () {
      this.CameraCount = 0;
      //this.pro = ProInfoCache.getProInfo()
      if (!this.pro) return;
      console.log(this.pro.Id);
      this.$http
        .post("/DeviceManage/DeviceVideoCamera/GetCameraTreeDataList", {
          ProId: this.pro.Id
        })
        .then(resJson => {
          this.videoList = resJson.Data;

          this.CameraCount = this.caculeteCount(
            this.videoList,
            this.CameraCount
          );
        });
    },

    caculeteCount (list, count) {
      if (!list || list.length <= 0) return count;
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (
          element.slots &&
          element.slots.icon &&
          element.slots.icon === "video-camera"
        )
          count++;
        if (element.Children && element.Children.length > 0)
          count = this.caculeteCount(element.Children, count);
      }
      return count;
    },
    InitScreen (num) {
      this.loading = true;
      this.ScreenNumber = num;
      this.LayoutScreens(num);
      this.loading = false;
      for (let index = 0; index < this.VueAliplayer.length; index++) {
        const element = this.VueAliplayer[index];
        //if (this.ScreenNumber <= index && element.Id) {
        element.Id = null;
        element.Data = null;
        element.Loading = {};
        //}
      }
    },
    convertAddress (address) {
      var a = address.replace(/^https?:\/\//i, "//")
      console.log(a)
      return a
    },
    SelectVideoAddress (selectedKeys, info) {
      if (!info.node.dataRef.LiveAddress) {
        message.warning("没有获取到播放地址");
        return;
      }
      let flag1 = this.VueAliplayer.filter(
        item => item.Id === info.node.dataRef.Id
      );
      if (flag1.length > 0) {
        //该视频在播放中
        // 如果 SelectVideoIndex 选中，
        // if (this.SelectVideoIndex === flag1[0].index) return;
        // else {
        //   let old = this.VueAliplayer.filter(
        //     (item) => item.index === this.SelectVideoIndex
        //   )[0];
        //   old.Id = info.node.dataRef.Id;
        //   old.address = info.node.dataRef.LiveAddress;
        //   flag1[0].address = null;
        //   flag1[0].Id = null;
        // }
        this.SelectVideoIndex = flag1[0].index;
        return;
      } else {
        let old = this.VueAliplayer.filter(
          item => item.index === this.SelectVideoIndex
        )[0];
        old.Id = info.node.dataRef.Id;
        old.Data = info.node.dataRef;
        old.PlayerOption = {
          autoplay: true,
          controls: true,
          muted: false,
          bigPlayButton: true,
          textTrackDisplay: false,
          posterImage: false,
          errorDisplay: false,
          preload: 'auto',
          hls: {
            withCredentials: true
          },
          sources: [
            {
              withCredentials: false,
              type: 'application/x-mpegURL',
              src: this.convertAddress(info.node.dataRef.LiveAddress)
            }
          ],
        }
        console.log('videoPlayer-' + old.index)
        old.player = this.$video('videoPlayer-' + this.SelectVideoIndex, this.options)
        old.player.src([
          {
            src: this.convertAddress(info.node.dataRef.LiveAddress),
            type: 'application/x-mpegURL'
          }
        ])
        old.player.play()

        old.Loading = {
          up: false,
          down: false,
          left: false,
          right: false,
          zoomout: false,
          zoomin: false,
          loading: false
        };
        old.visible = false;
        // old.address = info.node.dataRef.LiveAddress;
        // old.controlAble = info.node.dataRef.ControlAble;
      }
      this.SelectNextScreen();
    },
    SelectNextScreen () {
      let selectChanged = false;
      for (let index = 0; index < this.VueAliplayer.length; index++) {
        if (index === this.ScreenNumber) {
          break;
        }
        const element = this.VueAliplayer[index];
        if (element.Id) continue;
        selectChanged = true;
        this.SelectVideoIndex = index;
        break;
      }
      if (!selectChanged) {
        this.SelectVideoIndex = -1;
        this.SelectVideoIndex = 0;
      }
    },
    ScreenClick (i) {
      this.SelectVideoIndex = i;
    },
    LayoutScreens () {
      this.Container = this.$refs.center;
      for (var i = 0; i < 25; i++) {
        var video = this.Container.childNodes.item(i);
        if (video) {
          video.index = i;
          video.parentContainer = this.Container;
          if (this.ScreenNumber <= i) {
            video.style.display = "none";
          } else {
            video.style.display = "block";
          }
        }
      }

      var width = this.Container.offsetWidth;
      var height = this.Container.offsetHeight;
      var count = 0;
      for (let j = 0; j < this.Container.childElementCount; j++) {
        let video = this.Container.childNodes.item(j);
        if (this.ScreenNumber == 1 && video.index < 1) {
          video.style.width = width + "px";
          video.style.height = height + "px";
          count++;
        } else if (this.ScreenNumber == 4 && video.index < 4) {
          video.style.width = width / 2 + "px";
          video.style.height = height / 2 + "px";
          count++;
        } else if (this.ScreenNumber == 6 && video.index < 6) {
          if (video.index == 0) {
            video.style.width = (width / 3) * 2 + "px";
            video.style.height = (height / 3) * 2 + "px";
          } else {
            video.style.width = width / 3 + "px";
            video.style.height = height / 3 + "px";
          }
          count++;
        } else if (this.ScreenNumber == 8 && video.index < 8) {
          if (video.index == 0) {
            video.style.width = (width / 4) * 3 + "px";
            video.style.height = (height / 4) * 3 + "px";
          } else {
            video.style.width = width / 4 + "px";
            video.style.height = height / 4 + "px";
          }
          count++;
        } else if (this.ScreenNumber == 9 && video.index < 9) {
          video.style.width = width / 3 + "px";
          video.style.height = height / 3 + "px";
          count++;
        } else if (this.ScreenNumber == 16 && video.index < 16) {
          video.style.width = width / 4 + "px";
          video.style.height = height / 4 + "px";
          // video.style.cssFloat = "left";
          // video.style.margin = "1px";
          count++;
        } else if (this.ScreenNumber == 25 && video.index < 26) {
          video.style.width = width / 5 + "px";
          video.style.height = height / 5 + "px";
          // video.style.cssFloat = "left";
          // video.style.margin = "1px";
          count++;
        }
        if (count == this.ScreenNumber) {
          break;
        }
      }
      if (this.SelectVideoIndex > this.ScreenNumber - 1) {
        this.SelectVideoIndex = 0;
      }
    },
    closePTZ (camera) {
      camera.visible = false;
      this.$forceUpdate();
    },
    openPTZ (camera) {
      camera.visible = true;
      this.$forceUpdate();
    },
    //云台
    ptzcontrol (index, cmd) {
      // if (index.Loading.loading) return;
      var data = this.VueAliplayer.filter(x => x.Id === index)[0];
      this.setLoading(data, cmd, true);
      this.control(data, cmd, true);
    },
    stopcontrol (index, cmd) {
      var data = this.VueAliplayer.filter(x => x.Id === index)[0];
      this.setLoading(data, cmd, false);
      this.control(data, cmd, false);
    },
    control (index, cmd, start) {
      index.Loading.loading = true;
      this.$http
        .post("/DeviceManage/DeviceVideoCamera/Control", {
          CameraNo: index.Data.Id,
          Action: start ? 1 : 0,
          Command: cmd,
          Speed: 50
        })
        .then(() => {
          index.Loading.loading = false;
          this.$forceUpdate();
        });
    },
    setLoading (data, cmd, start) {
      data.Loading.loading = true;
      switch (cmd) {
        case "up":
          data.Loading.up = start;
          break;
        case "down":
          data.Loading.down = start;
          break;
        case "left":
          data.Loading.left = start;
          break;
        case "right":
          data.Loading.right = start;
          break;
        case "IRIS_ENLARGE":
          data.Loading.zoomout = start;
          break;
        case "IRIS_REDUCE":
          data.Loading.zoomin = start;
          break;
      }
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";

.tool-pln {
  border-radius: 4px;
  z-index: 12;
  position: absolute;
  text-shadow: 2px 2px 2px #000;
  .btn-pln {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .btn-item,
    .active {
      color: #fff;
      line-height: 28px;
      height: 28px;
      margin-left: 20px;
      width: 28px;
      .icon {
        width: 2em;
        height: 2em;
        font-size: 15px;
        cursor: pointer;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
      .icon:hover {
        color: #1a5cd7;
      }
    }
    .active {
      color: #1a5cd7;
    }
  }
}
#content {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0 0.3rem;
  height: 10.3rem;
  max-height: 11rem;
  overflow: hidden;
  h3 {
    font-weight: 900;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(249 242 242 / 85%);
  }

  #center {
    flex: 1;
    height: 100%;
    // margin-left: 1.4%;
    .item-box,
    .active-item-box {
      float: left;
      border: 1px solid white;
    }
    .active-item-box {
      border: 1px solid #1a5cd7;
    }

    /**播放器云台控制面板**/
    .ptz-block-center {
      position: absolute;
      z-index: 99999;
      height: 34px;
      width: 34px;
      bottom: 0;
      right: 0;
      text-align: center;
      font-size: 34px;
      border-radius: 16px;
    }
    .ptz-block {
      position: absolute;
      z-index: 99999;
      // height: 220px;
      // width: 150px;
      height: 160px;
      width: 100px;
      // right: 20px;
      // margin-top: 1%;
      bottom: 0;
      right: 10px;
      text-align: center;
      font-size: 24px;
      background: hsla(0, 0%, 93%, 0.6);
      border-radius: 16px;
      // overflow: hidden;
    }

    /**标准控制面板**/
    .ptz-block .ptz-cell {
      line-height: 50px;
      height: 50px;
      width: 50px;
      position: absolute;
      cursor: pointer;
      color: black;
    }

    .ptz-block .ptz-up {
      // top: 70px;
      // left: 50px;
      top: 40px;
      left: 25px;
    }

    .ptz-block .ptz-left {
      // top: 120px;、
      top: 70px;
      left: -5;
    }

    .ptz-block .ptz-center {
      // top: 120px;
      // left: 50px;
      // width: 50px;
      // height: 50px;
      // line-height: 50px;
      // position: absolute;
      // border-radius: 25px;
      top: 81px;
      left: 39px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      position: absolute;
      border-radius: 25px;
      background: hsla(0, 0%, 80%, 0.2);
      /* cursor: move; */
    }

    .ptz-block .ptz-right {
      // top: 120px;
      // left: 100px;

      top: 70px;
      right: -5px;
    }

    .ptz-block .ptz-down {
      // top: 165px;
      // left: 50px;
      bottom: -5px;
      left: 25px;
    }

    .ptz-block .ptz-plus {
      top: 0px;
      left: 5px;
    }

    .ptz-block .ptz-minus {
      // top: 25px;
      top: 0px;
      right: 5px;
    }
    .active-ptz {
      color: #656565;
    }
  }

  #right {
    width: 2.94rem;
    height: 100%;
    margin-left: 1.4%;

    background: #0f1325;
    .ant-tree-switcher,
    .ant-tree-icon__customize {
      color: #1a5cd7;
    }
    .ant-tree-title {
      color: #1a5cd7;
      width: 2.14rem;
      overflow: hidden; /*溢出的部分隐藏*/
      white-space: nowrap; /*文本不换行*/
      text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
    }
  }
}
/* 自定义WebKit浏览器的滚动条 */
::-webkit-scrollbar {
  width: 3px; /* 滚动条宽度 */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条颜色 */
  border-radius: 3px; /* 滚动条圆角 */
}

/* 自定义Firefox浏览器的滚动条 */
::-moz-scrollbar {
  width: 3px;
}

::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* 自定义IE和Edge浏览器的滚动条 */
::-ms-scrollbar {
  width: 3px;
}

::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}
</style>
